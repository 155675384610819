import {
  EmptyInfos,
  EmptyPhotoConfig,
  EmptyScanConfig,
  EmptySelectedOffers,
  EmptyVideoConfig,
  OrderPageState,
} from '../context/orderPageContextState';
import { fixBackendNullFieldsHack } from '../../../utils/fixFormUtils';
import { ShootingRestoreDTO } from '../../../appAPITypes';
import { OrderStep } from '../context/orderPageContextSteps';
import { useMemo } from 'react';
import { ShMoment } from '@shoootin/utils';

export const useOrderRestorationInitialState = (
  shootingId: string,
  shootingRestore?: ShootingRestoreDTO,
): {
  initialState?: OrderPageState;
  initialStep?: OrderStep;
} => {
  return useMemo(() => {
    console.debug(
      'useOrderRestorationInitialState ',
      shootingId,
      shootingRestore,
    );
    if (
      !shootingRestore ||
      !shootingRestore.success ||
      (shootingRestore.success && shootingRestore.alreadyConfirmed)
    ) {
      console.debug('!shootingRestore for ', shootingId, shootingRestore);
      return {};
    }

    const isSelectedSlotValid = shootingRestore.selectedSlotValid;

    // The previously selected slot is sent by the backend and actually used in a warning
    // but we want undefined if this slot is not valid anymore
    const selectedSlot = isSelectedSlotValid
      ? shootingRestore.order.shootingSlot
      : undefined;

    const initialStep: OrderStep = shootingRestore.selectedSlotValid
      ? 'payment'
      : 'book';

    const initialState: OrderPageState = {
      admin: shootingRestore.admin ? shootingRestore.admin : undefined,
      restoration: {
        shootingId,
        restoredFrom: shootingRestore,
      },
      offers: shootingRestore.offers,
      options: shootingRestore.options,
      currency: shootingRestore.currency,
      offerDiscount: shootingRestore.offerDiscount,
      availabilities: {
        availabilities: shootingRestore.availabilities,
        emptyDays: shootingRestore.emptyDays,
        canChange: true,
      },
      isSelectedSlotValid,
      orderSummary: shootingRestore.orderSummary,
      country: shootingRestore.order.address.country,
      address: shootingRestore.order.address,
      shootingType: shootingRestore.order.infos.shootingType,
      infos: fixBackendNullFieldsHack(shootingRestore.order.infos, EmptyInfos),
      selectedOffers: fixBackendNullFieldsHack(
        {
          // Locally we always have a config for each order component (for form manipulation conveniency)
          // But backend may send empty fields here so we "restore" the mandatory local objects
          photoConfig: EmptyPhotoConfig,
          videoConfig: EmptyVideoConfig,
          scanConfig: EmptyScanConfig,
          ...shootingRestore.order.offers,
        },
        EmptySelectedOffers,
      ),
      timeZone: shootingRestore.order.timeZone,
      selectedDay: shootingRestore.order.shootingSlot
        ? ShMoment(shootingRestore.order.shootingSlot.start)
        : ShMoment(),
      selectedSlot,
      registration: shootingRestore.registration,

      selectedPaymentMethod: shootingRestore.orderSummary
        ? shootingRestore.orderSummary.paymentMethods[0]
        : undefined,
      selectedCreditCardId: shootingRestore.order.creditCardId,
      saveCreditCard: true,
      reduceTotalWithCredit: false,
      unavailableSlot: isSelectedSlotValid
        ? undefined
        : shootingRestore.order.shootingSlot,
      clientExtraFields: shootingRestore.clientExtraFields,
      clientExtraFieldsMap: shootingRestore.order.clientExtraFieldsMap,
      clientBillingEntities: shootingRestore.clientBillingEntities,
      billingEntity: shootingRestore.order.billingEntity,
      canAddBillingEntity: shootingRestore.canAddBillingEntity,
      extraCharge: shootingRestore.order.extraCharge,
      discountCode: shootingRestore.order.discountCode,
      users: shootingRestore.users,
      userId: shootingRestore.userId,
    };

    return {
      initialState,
      initialStep,
    };
  }, [shootingRestore]);
};
