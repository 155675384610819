import './styles/orderPageStyle';
import React from 'react';
import { OrderPageContextProvider } from 'page/order/context/orderPageContext';
import OrderPageContent from './orderPageContent';
import { usePageParams } from '../../layout/appContext';
import { useAsync } from 'react-async-hook';
import OrderPageMainLayout from './orderPageMainLayout';
import { OrderAPI } from '../../appAPI';
import { isServer } from '../../utils/gatsbyUtils';
import OrderLayoutSpinner from './components/orderLayoutSpinner';
import { useOrderRestorationInitialState } from './utils/orderPageRestoreUtils';
import OrderLayoutError from './components/orderLayoutError';
import { useCurrentClientUser } from '../../state/currentUserState';
import { AppInternalPaths, useNavigate } from '../../primitives/appLink';
import PageNotFound from '../pageNotFound';
import { redirectBrowser } from '../../appNavigation';
import { clientHomeUrl } from 'appEnv';

// Wrapping here on purpose
// MainLayout has to render first otherwise useHeaderSize returns default value!
const OrderPageRestore = () => {
  const { shootingId } = usePageParams();
  const currentUser = useCurrentClientUser();
  const navigate = useNavigate();
  console.log('OrderPageRestore currentUser =', currentUser);

  if (isServer() || !shootingId) {
    return <OrderLayoutSpinner />;
  }

  if (!currentUser) {
    console.debug(
      'OrderPageRestore but no loggedIn ClientUser -> redirect to login',
    );
    // use navigate with state with redirect url ?
    // navigate.toLogin();
    //TODO using navigate -->
    //Warning: Can't perform a React state update on an unmounted component.
    //&
    //You have included the Google Maps JavaScript API multiple times on this page. This may cause unexpected errors.

    redirectBrowser(AppInternalPaths.login);
    return <OrderLayoutSpinner />;
  }

  const asyncRestoredShooting = useAsync(OrderAPI.restoreShooting, [
    shootingId,
  ]);
  const { initialState, initialStep } = useOrderRestorationInitialState(
    shootingId,
    asyncRestoredShooting.result,
  );

  if (!asyncRestoredShooting.result) {
    if (asyncRestoredShooting.error) {
      return <OrderLayoutError />;
    }
    return <OrderLayoutSpinner />;
  } else {
    if (!asyncRestoredShooting.result.success) {
      //shooting does not exists on account of clientUser.
      return (
        <PageNotFound
          message="Shooting not found"
          infos={
            // TODO translate
            'The shooting you are trying to validate does not exist in your account.'
          }
        />
      );
    } else {
      if (asyncRestoredShooting.result.alreadyConfirmed) {
        //shooting already confirmed
        redirectBrowser(clientHomeUrl());
        return <OrderLayoutSpinner />;
      }
    }
  }

  console.debug(
    'Restore shooting',
    shootingId,
    asyncRestoredShooting,
    initialState,
    initialStep,
  );

  return (
    <OrderPageMainLayout>
      <OrderPageContextProvider
        initialState={initialState}
        initialStep={initialStep}
      >
        <OrderPageContent />
      </OrderPageContextProvider>
    </OrderPageMainLayout>
  );
};

export default OrderPageRestore;
